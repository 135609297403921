import { computed, inject, Injectable, signal } from "@angular/core";
import { lastValueFrom, Subscription } from "rxjs";

import { ListHelperInterface, ListInterface, UserInterface } from "@common/ports/interfaces";
import { ListAbstraction } from "@common/ports/abstractions";
import { DataUserHelper, ListHelper } from "@common/domain/helpers";

import { CityInterface } from "@city/ports/interfaces";
import { ClientInterface } from "@clients/ports/interfaces";
import { CountryInterface } from "@countries/ports/interfaces";
import { DeliveryTypeInterface } from "@delivery-type/ports/interfaces";
import { FormPaymentInterface } from "@form-payment/ports/interfaces";
import { InstitutionInterface } from "@institution/ports/interfaces";
import { ProductServiceInterface } from "@products-services/ports/interfaces";
import { RolPermissionInterface } from "@roles-permissions/ports/interfaces";
import { FilterSaleInterface, SaleInterface } from "@sales/ports/interfaces";
import { StateInterface } from "@state/ports/interfaces";
import { TableLazyLoadEvent } from "primeng/table";

@Injectable({
  providedIn: 'root'
})
export class ListService {
  private listHelper: ListHelperInterface = new ListHelper();

  private listAbstraction = inject(ListAbstraction);

  private _respCity: Subscription = new Subscription();
  private _respClient: Subscription = new Subscription();
  private _respCountry: Subscription = new Subscription();
  private _respDeliveryType: Subscription = new Subscription();
  private _respInstitution: Subscription = new Subscription();
  private _respPaid: Subscription = new Subscription();
  private _respProduct: Subscription = new Subscription();
  private _respRol: Subscription = new Subscription();
  private _respSale: Subscription = new Subscription();
  private _respState: Subscription = new Subscription();
  private _respTypeDocument: Subscription = new Subscription();
  private _respUser: Subscription = new Subscription();

  private countCityTemp = signal<number>(0);
  private countClientTemp = signal<number>(0);
  private countCountryTemp = signal<number>(0);
  private countDeliveryTypeTemp = signal<number>(0);
  private countInstitutionTemp = signal<number>(0);
  private countPaidTemp = signal<number>(0);
  private countProductTemp = signal<number>(0);
  private countRolTemp = signal<number>(0);
  private countSaleTemp = signal<number>(0);
  private countStateTemp = signal<number>(0);
  private countUserTemp = signal<number>(0);

  private listCityTemp = signal<CityInterface[]>([]);
  public listClientTemp = signal<ClientInterface[]>([]);
  private listCountryTemp = signal<CountryInterface[]>([]);
  private listDeliveryTypeTemp = signal<DeliveryTypeInterface[]>([]);
  private listInstitutionTemp = signal<InstitutionInterface[]>([]);
  private listPaidTemp = signal<FormPaymentInterface[]>([]);
  private listProductTemp = signal<ProductServiceInterface[]>([]);
  private listRolTemp = signal<RolPermissionInterface[]>([]);
  private listSaleTemp = signal<SaleInterface[]>([]);
  private listStateTemp = signal<StateInterface[]>([]);
  private listTypeDocumentTemp = signal<ListInterface[]>([]);
  private listUserTemp = signal<UserInterface[]>([]);

  public isBtnSearchCity = signal<boolean>(false);
  public isBtnSearchClient = signal<boolean>(false);
  public isBtnSearchCountry = signal<boolean>(false);
  public isBtnSearchDeliveryType = signal<boolean>(false);
  public isBtnSearchInstitution = signal<boolean>(false);
  public isBtnSearchPaid = signal<boolean>(false);
  public isBtnSearchProduct = signal<boolean>(false);
  public isBtnSearchRol = signal<boolean>(false);
  public isBtnSearchSale = signal<boolean>(false);
  public isBtnSearchState = signal<boolean>(false);
  public isBtnSearchUser = signal<boolean>(false);

  public btnSearchCity = signal<number>(10);
  public btnSearchClient = signal<number>(10);
  public btnSearchCountry = signal<number>(10);
  public btnSearchDeliveryType = signal<number>(10);
  public btnSearchInstitution = signal<number>(10);
  public btnSearchPaid = signal<number>(10);
  public btnSearchProduct = signal<number>(10);
  public btnSearchRol = signal<number>(10);
  public btnSearchSale = signal<number>(10);
  public btnSearchState = signal<number>(10);
  public btnSearchUser = signal<number>(10);

  private institution = signal<InstitutionInterface>({} as InstitutionInterface);

  public countCity = computed(() => this.countCityTemp());
  public countClient = computed(() => this.countClientTemp());
  public countCountry = computed(() => this.countCountryTemp());
  public countDeliveryType = computed(() => this.countDeliveryTypeTemp());
  public countInstitution = computed(() => this.countInstitutionTemp());
  public countPaid = computed(() => this.countPaidTemp());
  public countProduct = computed(() => this.countProductTemp());
  public countRol = computed(() => this.countRolTemp());
  public countSale = computed(() => this.countSaleTemp());
  public countState = computed(() => this.countStateTemp());
  public countUser = computed(() => this.countUserTemp());

  public listCity = computed(() => this.listCityTemp());
  public listClient = computed(() => this.listClientTemp());
  public listCountry = computed(() => this.listCountryTemp());
  public listDeliveryType = computed(() => this.listDeliveryTypeTemp());
  public listInstitution = computed(() => this.listInstitutionTemp());
  public listPaid = computed(() => this.listPaidTemp());
  public listProduct = computed(() => this.listProductTemp());
  public listRol = computed(() => this.listRolTemp());
  public listSale = computed(() => this.listSaleTemp());
  public listState = computed(() => this.listStateTemp());
  public listTypeDocument = computed(() => this.listTypeDocumentTemp());
  public listUser = computed(() => this.listUserTemp());

  public listStateSale = computed<ListInterface[]>(() => [
    { id: 'Borrador', active: true, code: 'Borrador', name: 'Borrador', description: '', updateDate: '', creationDate: '' },
    { id: 'Pendiente', active: true, code: 'Pendiente', name: 'Pendiente', description: '', updateDate: '', creationDate: '' },
    { id: 'Procesando', active: true, code: 'Procesando', name: 'Procesando', description: '', updateDate: '', creationDate: '' },
    { id: 'Alistamiento', active: true, code: 'Alistamiento', name: 'Alistamiento', description: '', updateDate: '', creationDate: '' },
    { id: 'Entregado', active: true, code: 'Entregado', name: 'Entregado', description: '', updateDate: '', creationDate: '' },
    { id: 'Cancelado', active: true, code: 'Cancelado', name: 'Cancelado', description: '', updateDate: '', creationDate: '' },
  ]);
  public listStateProduct = computed<ListInterface[]>(() => [
    { id: 'Pendiente', active: true, code: 'Pendiente', name: 'Pendiente', description: '', updateDate: '', creationDate: '' },
    { id: 'Procesando', active: true, code: 'Procesando', name: 'Procesando', description: '', updateDate: '', creationDate: '' },
    { id: 'Alistamiento', active: true, code: 'Alistamiento', name: 'Alistamiento', description: '', updateDate: '', creationDate: '' },
    { id: 'En Oficina', active: true, code: 'En Oficina', name: 'En Oficina', description: '', updateDate: '', creationDate: '' },
    { id: 'Enviado', active: true, code: 'Enviado', name: 'Enviado', description: '', updateDate: '', creationDate: '' },
  ]);
  public listCurrency = computed<ListInterface[]>(() => [
    { active: true, code: 'COP', name: 'Pesos colombianos', creationDate: '', description: '', id: 'COP', updateDate: '' },
    { active: true, code: 'USD', name: 'Dolar', creationDate: '', description: '', id: 'USD', updateDate: '' }
  ]);
  public listActive = computed(() => [
    { id: '', code: '', name: 'Todos', active: false, creationDate: '', description: '', updateDate: '' },
    { id: '', code: 'true', name: 'Si', active: false, creationDate: '', description: '', updateDate: '' },
    { id: '', code: 'false', name: 'No', active: false, creationDate: '', description: '', updateDate: '' },
  ]);

  setInstitution(institution: InstitutionInterface) {
    this.institution.update(() => institution);
  }

  getCity(searchValue?: string, isActive: boolean = true): void {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchCity(), this.btnSearchCity());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      if (searchValue) query += `&search=${searchValue}`;
      if (isActive) query += `&isActive=true`;

      this._respCity = this.listAbstraction.listCity(query)
        .subscribe(resp => {
          this.listCityTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
          this.countCityTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  getClient(searchValue?: string, isActive: boolean = true): void {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchClient(), this.btnSearchClient());
      this.btnSearchClient.update(() => limit);

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      if (searchValue) query += `&search=${searchValue}`;
      if (isActive) query += `&active=true`;

      this._respClient = this.listAbstraction.listClient(query)
        .subscribe(resp => {
          this.listClientTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
          this.countClientTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  getCountry(searchValue?: string, isActive: boolean = true): void {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchCountry(), this.btnSearchCountry());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      if (searchValue) query += `&search=${searchValue}`;
      if (isActive) query += `&isActive=true`;

      this._respCountry = this.listAbstraction.listCountry(query)
        .subscribe(resp => {
          this.listCountryTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
          this.countCountryTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  getDeliveryType(searchValue?: string, isActive: boolean = true): void {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchDeliveryType(), this.btnSearchDeliveryType());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      if (searchValue) query += `&search=${searchValue}`;
      if (isActive) query += `&isActive=true`;

      this._respDeliveryType = this.listAbstraction.listDeliveryType(query)
        .subscribe(resp => {
          this.listDeliveryTypeTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
          this.countDeliveryTypeTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  getInstitution(searchValue?: string, isActive: boolean = true): void {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchInstitution(), this.btnSearchInstitution());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      if (searchValue) query += `&search=${searchValue}`;
      if (isActive) query += `&isActive=true`;

      this._respInstitution = this.listAbstraction.listInstitution(query)
        .subscribe(resp => {
          this.listInstitutionTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
          this.countInstitutionTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  getPaid(searchValue?: string, isActive: boolean = true): void {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchPaid(), this.btnSearchPaid());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      if (searchValue) query += `&search=${searchValue}`;
      if (isActive) query += `&isActive=true`;

      this._respPaid = this.listAbstraction.listPaid(query)
        .subscribe(resp => {
          this.listPaidTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
          this.countPaidTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  getProduct(searchValue?: string, isActive: boolean = true): void {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchProduct(), this.btnSearchProduct());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');

      if (this.institution().id) query += `&institutionId=${this.institution().id}`;
      if (searchValue) query += `&search=${searchValue}`;
      if (isActive) query += `&isActive=true`;

      this._respProduct = this.listAbstraction.listProduct(query)
        .subscribe(resp => {
          this.listProductTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
          this.countProductTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  getRol(searchValue?: string): void {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchRol(), this.btnSearchRol());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');
      if (searchValue) query += `&search=${searchValue}`;

      this._respRol = this.listAbstraction.listRol(query)
        .subscribe(resp => {
          this.listRolTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
          this.countRolTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  getSale(searchValue?: string): void {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchSale(), this.btnSearchSale());

      let query = this.listHelper.buildQueryString(1, limit, (searchValue) ? searchValue : null, 'code');

      this._respSale = this.listAbstraction.listSale(query)
        .subscribe(resp => {
          this.listSaleTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
          this.countSaleTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  getSaleFilter(queryTable: TableLazyLoadEvent, filterValue: FilterSaleInterface | null, searchValue: string | null): void {
    try {
      let query = this.listHelper.getListQuery(queryTable, (searchValue) ? searchValue : null, (filterValue) ? filterValue : null, 'code');

      this._respSale = this.listAbstraction.listSale(query)
        .subscribe(resp => {
          this.listSaleTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
          this.countSaleTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  getState(searchValue?: string, isActive: boolean = true): void {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchState(), this.btnSearchState());

      let query = this.listHelper.buildQueryString(1, limit, null, 'name');
      if (searchValue) query += `&search=${searchValue}`;
      if (isActive) query += `&isActive=true`;

      this._respState = this.listAbstraction.listState(query)
        .subscribe(resp => {
          this.listStateTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
          this.countStateTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  getTypeDocument(): void {
    try {
      this._respTypeDocument = this.listAbstraction.listTypeDocument()
        .subscribe(resp => {
          this.listTypeDocumentTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].data : []);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  getUser(searchValue?: string, isActive: boolean = true): void {
    try {
      const limit = this.listHelper.calculateLimit(this.isBtnSearchUser(), this.btnSearchUser());

      let query = this.listHelper.buildQueryString(1, limit, null, 'firstName');

      if (searchValue) query += `&search=${searchValue}`;
      if (isActive) query += `&active=true`;

      this._respUser = this.listAbstraction.listUser(query)
        .subscribe(resp => {
          const listUser = this.formantFullName((resp && resp.data.length > 0) ? resp.data[0].data : []);
          this.listUserTemp.update(() => listUser);
          this.countUserTemp.update(() => (resp && resp.data.length > 0) ? resp.data[0].count : 0);
        });
    } catch (error) {
      this.listHelper.handleError(error);
    }
  }

  private formantFullName(respData: UserInterface[]) {
    const newData: UserInterface[] = [];
    for (let index = 0; index < respData.length; index++) {
      const dataList = respData[index];
      const data: UserInterface = {
        ...dataList,
        fullName: `${dataList.firstName} ${dataList.lastName}`,
      };
      newData.push(data);
    }
    return newData;
  }

  onDestroy() {
    this._respCity.unsubscribe();
    this._respClient.unsubscribe();
    this._respCountry.unsubscribe();
    this._respDeliveryType.unsubscribe();
    this._respInstitution.unsubscribe();
    this._respPaid.unsubscribe();
    this._respProduct.unsubscribe();
    this._respRol.unsubscribe();
    this._respSale.unsubscribe();
    this._respState.unsubscribe();
    this._respTypeDocument.unsubscribe();
    this._respUser.unsubscribe();
  }
}
